import { useEffect, useRef } from "react";
import CountUp from 'react-countup';
import coin_icon from '../../assets/images/icons/coin.svg'

import "./CoinsCounter.css"

export default function CoinsCounter(props) {
    const prevCountRef = useRef();

    useEffect(() => {
        //assign the ref's current value to the count Hook
        prevCountRef.current = props.total_coins;
    },[props])

    return (
        <div id="coins_counter">
            <span className="coins_label">I miei gettoni:</span>
            <span className="coins_count"><img src={coin_icon} /> <CountUp start={prevCountRef.current} end={props.total_coins}/></span>
        </div>
    )
}