import "./InitialScreen.css";
import React, { useState, useEffect, useContext } from "react";
import Countdown from 'react-countdown';
import CallServer from '../../apis/apis'
import { useNavigate, useLocation } from "react-router-dom";
import * as gtag from '../../utils/gtag' 

import { AppAuth } from "../../Auth";
import InfoModal from "../_components/InfoModal";

//Images
import backpack_img from "../../assets/images/icons/backpack.svg";
import gems_icon from "../../assets/images/icons/gems.svg";
import gem_icon from "../../assets/images/icons/gem.svg";
import crown_coins_img from "../../assets/images/icons/crown-coins.svg";
import user_right from "../../assets/images/icons/user_right.svg";
import user_left from "../../assets/images/icons/user_left.svg";
import clock_img from "../../assets/images/icons/clock.svg";
import ErrorModal from "../_components/ErrorModal";

function InitialScreen() {
    
    const [isloaded, setIsLoaded] = useState(false);  
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [rewardSession, setRewardSession] = useState(null);
    const [errorModalDetails, setErrorModalDetails] = useState({
        show: false,
        title: "",
        text: "",
        cta_text: "",
        redirect_in_app: false
    })
    const [sessionHasChanged, setSessionHasChanged] = useState(false)

    const { firebase_idToken, setFirebase_idToken } = useContext(AppAuth);

    let navigate = useNavigate();
    let location = useLocation();

    const loadRewardWindow = async () => {
        if(firebase_idToken && firebase_idToken != ""){
            let response = await CallServer.get_reward_window(firebase_idToken);
            if(response.success){
                setRewardSession(
                    response.data
                )
                setIsLoaded(true)
            } else {
                if(!response.error)
                    setErrorModalDetails({
                        show: true,
                        title: "Alla prossima...",
                        text: "Per il momento non è previsto nessun'altro Apri&Vinci, continua a giocare con le Sfide e torna a trovarci per scoprire i prossimi appuntamenti!",
                        cta_text: "Gioca una sfida",
                        redirect_in_app: true
                    })
                else
                    setErrorModalDetails({
                        show:true,
                        title:"Oops!",
                        text: "C'è stato un errore durante il caricamento del concorso!, prova a chiudere e riaprire l'app!"
                    })
            }
        }
    }


    useEffect (() => {
        loadRewardWindow()
    }, [firebase_idToken]);

    useEffect(() => {
        setTimeout(()=>{loadRewardWindow()},5000)
    }, [sessionHasChanged]);

    const isToday = (someDate) => {
        const rewardDate =  new Date(someDate);
        const today = new Date()
        return rewardDate.getDate() == today.getDate() &&
            rewardDate.getMonth() == today.getMonth() &&
            rewardDate.getFullYear() == today.getFullYear()
    }

    const handleOpenRewardScreen = () => {
        if (firebase_idToken !== "") {
            gtag.event({ action: "open_backpack_screen", category: "User Actions", label: "Open Backpack Screen" })
            navigate("/backpackLottery", { replace: true });
        }
    }
    const handleOpenInfoPopup = () => {
        gtag.event({ action: "open_info_popup", category: "Info Actions", label:"Open Info Popup"})
        setShowInfoModal(true);
    }
    const handleCloseInfoPopup = () => {
        setShowInfoModal(false);
    }
    const handleCloseErrorModal = (redirect) => {
        if(redirect)
            window.location = "https://thefacultyapp.com/?APP#BESTOF#GENERAL";
        setErrorModalDetails({
            ...errorModalDetails,
            show: false
        });
    }

    const handleStartRewardSession = () => {
        setSessionHasChanged(true);
    }
    const handleEndRewardSession = () => {
        setSessionHasChanged(true);
    }

    const countdownRendererSmall = ({ days,hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <span>qualche secondo...</span>;
        } else {
            // Render a countdown
            return <span>{days}g {hours}h {minutes}min {days === 0 && hours === 0 && minutes < 10 ? seconds+"s": ""}</span>;
        }
    };
    return (
        <div id="initialScreenContainer" style={{height: '100vh'}} >
            {!isloaded ? <div className="loader"></div> : '' }
            
            <div id="header_home">
                <img src={user_left} className="user_home_left_icon" alt="" />
                <img src={user_right} className="user_home_right_icon" alt="" />
                <div className="d-flex justify-content-center">
                    <img
                        alt="Zaino"
                        className="noselect header_image"
                        src={backpack_img}
                    />
                </div>
                <h1 id="initialScreenTitle">Apri & Vinci</h1>
                <p id="initialScreenText">Usa i gettoni per aprire gli zaini: ogni mese puoi provare a vincere il superpremio da 500<img src={gem_icon} alt="Gemma" className="inline_icon" />!</p>
            </div>
            <div id="initialScreenList">
                <div className="list_element">
                    <img
                        alt="Gettoni"
                        className="noselect list_image"
                        src={crown_coins_img}
                    />
                    <div>
                        <h3>Accumula gettoni</h3>
                        <p>Gioca alle sfide in app per ottenere tantissimi gettoni!</p>
                    </div>
                    
                </div>
                <div className="list_element">
                    <img
                        alt="Zaino"
                        className="noselect list_image"
                        src={backpack_img}
                    />
                    <div>
                        <h3>100 gettoni = 1 zaino</h3>
                        <p>Finché hai gettoni, non c’è limite agli zaini che puoi aprire!</p>
                    </div>
                </div>
                <div className="list_element">
                    <img
                        alt="Gemme"
                        className="noselect list_image"
                        src={gems_icon}
                    />
                    <div>
                        <h3>Vinci 500€ in gemme </h3>
                        <p>1<img src={gem_icon} alt="Gemma" className="inline_icon" /> = 1€. Ogni mese, un giocatore fortunato tra i partecipanti troverà 500 gemme in uno zaino!</p>
                    </div>
                </div>
            </div>
            <div id="bottomButtonsInitialScreen">
                {isloaded  ? 
                <>
                { rewardSession && new Date() >= new Date(rewardSession.start_date) ? 
                            <div id="currentSessionBox">

                                {firebase_idToken !== "" || firebase_idToken === "" ?
                                    <>
                                        <span id="boxSpan"><img src={clock_img} className="inline_icon" style={{ marginRight: "8px" }} />Finisce tra <span id="sessionEndCountdown"><Countdown date={rewardSession.end_date} renderer={countdownRendererSmall} onComplete={handleEndRewardSession} /></span></span>
                                        <button id="openRewardButton" onClick={handleOpenRewardScreen} >
                                            Apriamo qualche zaino!
                                        </button>
                                    </>
                                    :
                                    ""
                                }
                            </div>
                        :
                            <div id="futureSessionBox">

                                {firebase_idToken !== "" || firebase_idToken === "" ?
                                    <>
                                        <span id="boxSpan"><img src={clock_img} className="inline_icon" style={{ marginRight: "8px" }} />Prossimo Apri & Vinci tra <span id="sessionCountdown"><Countdown date={rewardSession.start_date} renderer={countdownRendererSmall} onComplete={handleStartRewardSession} /></span></span>
                                        <button id="openRewardButton"  disabled>
                                            Apriamo qualche zaino!
                                        </button>
                                    </>
                                    :
                                    ""
                                }
                            </div>
                        
                        }
                </> : ""}
                <button id="contestInfoButton" className={rewardSession && new Date() >= new Date(rewardSession.start_date) ? "grey_btn" : ""} onClick={handleOpenInfoPopup}>
                    Come funziona?
                </button> 
                <InfoModal show={showInfoModal} handleClose={handleCloseInfoPopup} />
                <ErrorModal show={errorModalDetails.show} handleClose={handleCloseErrorModal} title={errorModalDetails.title} text={errorModalDetails.text} cta_text={errorModalDetails.cta_text} redirect_in_app={errorModalDetails.redirect_in_app} />
            </div>
        </div>
    );
}

export default InitialScreen;

