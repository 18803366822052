export function  isJSON  (object)  {
    var objectConstructor = ({}).constructor;
    if (object === null) {
        return false;
    }
    if (object === undefined) {
        return false;
    }
    if (object.constructor === objectConstructor) {
        return true;
    }
    return false;
    
}

export function generateID ()  {
    var result = 'DBX';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 5; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}