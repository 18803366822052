import Modal from "react-modal"
import { useEffect, useLayoutEffect, useRef } from "react";
import * as gtag from '../../utils/gtag' 

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import "./Modal.css"

import backpack_img from "../../assets/images/icons/backpack.svg";
import gem_icon from "../../assets/images/icons/gem.svg";
import question_icon from "../../assets/images/icons/question.svg";
import user_right from "../../assets/images/icons/user_right.svg";
import user_left from "../../assets/images/icons/user_left.svg";
import gems_icon from "../../assets/images/icons/gems.svg";
import crown_coins_img from "../../assets/images/icons/crown-coins.svg";

export default function ContestInfoModal(props) {
    const onCloseModal = () => {
        props.handleClose();
    }
    const openMoreModal = () => {
        props.openMoreModal();
    }
    const style = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 10000
        },
        content: {
            position: 'absolute',
            left: '0',
            bottom: '0',
            width: '100%',
            border: '0',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderTopRightRadius: '20px',
            borderTopLeftRadius: '20px',
            outline: 'none',
            padding: '0'
        }
    }
    const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <span>0 giorni</span>;
        } else {
            // Render a countdown
            return <span>{days}gg {hours}h {minutes}min</span>;
        }
    };

    const accordionRef = useRef(new Array())

    const scrollToTop = (e) => {
        let fields = Object.keys(accordionRef).length !== 0 ? accordionRef.current : {};
        if (Object.keys(fields).length === 0){
            return;
        }    
        for (let i in fields) {
            let div = fields[i]
            if(div)
                if (div.parentElement.id === 'accordion__panel-'+e[0])
                    div.parentElement.scrollIntoView()
        }
        gtag.event({ action: "open_accordion_info", category: "User Info Action", label: "Open Info Accordion - "+e[0] })
    };

    useLayoutEffect(() => {
    })

    useEffect(() => {

    },[props])

    return (
        <>
            <Modal
                closeTimeoutMS={300}
                isOpen={props.show}
                contentLabel="modal"
                onRequestClose={onCloseModal}
                className="bottom_modal"
                overlayClassName="bottom_modal_overlay"
                ariaHideApp={false}
                style={style}
            >
                <div className="modal-content">
                <div id="header_home">
                    <img src={user_left} className="user_home_left_icon" alt="" />
                    <img src={user_right} className="user_home_right_icon" alt="" />
                    <div className="d-flex justify-content-center">
                        <img
                            alt="Zaino"
                            className="noselect header_image"
                            src={backpack_img}
                        />
                    </div>
                    <h1 id="initialScreenTitle">Apri & Vinci</h1>
                    <p id="initialScreenText">Usa i gettoni per aprire gli zaini: ogni giorno puoi vincere decine di<img src={gem_icon} alt="Gemma" className="inline_icon" /><span className="fw-800">gemme</span>!</p>
                </div>
                <div id="initialScreenList">
                    <div className="list_element">
                        <img
                            alt="Gettoni"
                            className="noselect list_image"
                            src={crown_coins_img}
                        />
                        <div>
                            <h3>Accumula gettoni</h3>
                            <p>Gioca alle sfide o invita un amico su thefaculty per ottenere gettoni più velocemente</p>
                        </div>
                        
                    </div>
                    <div className="list_element">
                        <img
                            alt="Zaino"
                            className="noselect list_image"
                            src={backpack_img}
                        />
                        <div>
                            <h3>100 gettoni = 1 zaino</h3>
                            <p>Finché hai gettoni, non c’è limite agli zaini che puoi aprire!</p>
                        </div>
                    </div>
                    <div className="list_element">
                        <img
                            alt="Gemme"
                            className="noselect list_image"
                            src={gems_icon}
                        />
                        <div>
                            <h3>Vinci gemme e premi </h3>
                            <p>1<img src={gem_icon} alt="Gemma" className="inline_icon" /> = 1€. Ogni giorno puoi trovare negli zaini gemme e premi... Che la fortuna sia con te!</p>
                        </div>
                    </div>
                </div>
                    <a href="mailto:assistenza@thefaculty.it" title="Scrivi alla nostra assistenza" className="modalLink">Domande? Contatta la nostra assistenza</a>
                    <button id="closeModalButton" onClick={onCloseModal} >
                        Apriamo qualche zaino!
                    </button>
                    <button className="grey_btn" id="fullRulesButton" onClick={openMoreModal}>
                        Più informazioni
                    </button> 
                </div>
            </Modal>
        </>
    );
}

