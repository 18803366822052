import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://a2f81570238f4fe1a8b38798884cb84e@o1240163.ingest.sentry.io/4504853601452032",
  integrations: [new BrowserTracing()],
  release: process.env.REACT_APP_VERSION,
  environment: process.env.REACT_APP_ENVIRONMENT,
  tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_SESSION_RATE)
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename='/'>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
