import { useEffect } from "react";

import coin_icon from '../../assets/images/icons/coin.svg'

import "./BackpackPopover.css"

export default function MissingCoinsPopover(props) {
    useEffect(() => {

    }, [props])

    return (
        <div id="coins_popover_container" className={(props.show ? "show" : "")}>
            <div>
                <span className="popover_text">Ti mancano solo <img src={coin_icon} /> {props.missing_coins_count}!</span>
                <a href="https://thefacultyapp.com/?APP#BESTOF#GENERAL"><button >Gioca e ottieni gettoni</button></a>
            </div>

        </div>
    )
}