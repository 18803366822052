import "./BackpackScreen.css";
import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as gtag from '../../utils/gtag'
import { AppAuth } from "../../Auth";
import CallServer from "../../apis/apis"
import InfoModal from "../_components/InfoModal";
import Lottie from 'lottie-react';
import CoinsCounter from "../_components/CoinsCounter";

//Sentences
import { avatars_sentences } from "../../assets/avatars_sentences";

//Animations
import backpackAnimationData from '../../assets/animations/backpack-no-tooltip.json'
import backpackBgAnimationData from '../../assets/animations/light.json'

//Images
import gem_icon from "../../assets/images/icons/gem.svg";
import user_right from "../../assets/images/icons/avatar_contest_right.svg";
import user_left from "../../assets/images/icons/avatar_contest_left.svg";
import user_right_sad from "../../assets/images/icons/avatar_contest_right_sad.svg";
import user_left_sad from "../../assets/images/icons/avatar_contest_left_sad.svg";
import user_right_happy from "../../assets/images/icons/avatar_contest_right_happy.svg";
import user_left_happy from "../../assets/images/icons/avatar_contest_left_happy.svg";
import coin_icon from "../../assets/images/icons/coin.svg";
import question_bullet_icon from "../../assets/images/icons/question_bullet.svg";

import BackpackPopover from "../_components/BackpackPopover";
import AvatarsPopover from "../_components/AvatarsPopover";
import MissingCoinsPopover from "../_components/MissingCoinsPopover";
import RedeemPrizeModal from "./RedeemPrizeModal";
import ErrorModal from "../_components/ErrorModal";
import ContestInfoModal from "../_components/ContestInfoModal";
import InfoAvatar from "../_components/InfoAvatar";

function BackpackScreen() {

    const [isloaded, setIsLoaded] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [showContestModal, setShowContestModal] = useState(false);
    const [showInfoAvatar, setShowInfoAvatar] = useState(false);
    const [isFirstAttempt, setIsFirstAttempt] = useState(true);
    const [attemptResult, setAttemptResult] = useState(null);
    const [showPrizeModal, setShowPrizeModal] = useState(false)
    const [showAttemptResult, setShowAttemptResult] = useState(false)
    const [animationPlaying, setAnimationPlaying] = useState(false)
    const [attemptStarted, setAttemptStarted] = useState(false)
    const [avatarsInfo, setAvatarsInfo] = useState({
        randomPosition: Math.random(),
        randomSentence: ""
    })
    const [userCoins, setUserCoins] = useState({
        total_coins: 0,
        showMissingCoinsPopover: false
    })
    const [contestInfo, setContestInfo] = useState({
        lottery_attempt_currency_amount: 100,
        contest_currency: "coins"
    })
    const [errorModalDetails, setErrorModalDetails] = useState({
        show: false,
        title: "",
        text: "",
        cta_text: "",
        redirect_in_app: false
    })
    const SaveToLocalStorage = function (key, obj) {
        return localStorage.setItem(key, JSON.stringify(obj))
    }  
    const GetFromLocalStorage = (key)  => {
        return JSON.parse(localStorage.getItem(key))
    }   
    const lottieRef = useRef();
    const lottieBgRef = useRef();
    const { firebase_idToken, setFirebase_idToken } = useContext(AppAuth);

    let navigate = useNavigate();
    let location = useLocation();

    const new_contest = new Date("2022-08-01");

    const isToday = (someDate) => {
        const today = new Date()
        return someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
    }

    const handleOpenInfoPopup = () => {
        gtag.event({ action: "open_info_popup", category: "Info Actions", label: "Open Info Popup" })
        setShowInfoModal(true);
    }
    const handleCloseInfoPopup = () => {
        setShowInfoModal(false);
    }
    const handleCloseContestModal = () => {
        setShowContestModal(false);
        SaveToLocalStorage("first_access",{popup_show:true})
    }
    const handleCloseInfoAvatar = () => {
        setShowInfoAvatar(false);
        SaveToLocalStorage("second_access",{avatar_show:true})
    }
    const handleOpenMoreInfo = () => {
        setShowContestModal(false);
        setShowInfoAvatar(true);
    }

    const handleAttemptLottery = async () => {
        if(!firebase_idToken || firebase_idToken === ""){
            navigate("/",{replace:true});
            return;
        }
        if(userCoins.total_coins >= 100){
            setAttemptStarted(true);
            let attempt_response = await CallServer.attempt_lottery(firebase_idToken);
            if(attempt_response.success){
                console.log("Successfull Attempt -> " + attempt_response.data)
                setAttemptResult(attempt_response.data)
                if (!animationPlaying) {
                    lottieRef.current.stop()
                    lottieBgRef.current.stop()
                    setShowAttemptResult(false)
                    console.log("Successflully Reset Animation")
                    lottieRef.current.play()
                    setAnimationPlaying(true)
                    setAvatarsInfo({
                        randomPosition: Math.random(),
                        randomSentence: attempt_response.data ? "E con queste mi ritiro dagli studi, ciao povery!" : avatars_sentences[[Math.floor(Math.random() * avatars_sentences.length)]]
                    })
                    console.log("Successflully Start Animation")
                    loadUserCoins()
                    gtag.event({ action: "lottery_attempt", category: "Backpack Open", label: "Backpack Open Successfully " })
                }
            } else {
                setErrorModalDetails({
                    show:true,
                    title:"Oops...",
                    text:"C'è stato un errore mentre tentavamo di aprire il tuo zaino, prova a chiudere e riaprire l'app!"
                })
                console.log("Error While Attempting Lottery: " + attempt_response.error)
                gtag.event({ action: "lottery_attempt_error", category: "Error Backpack Open", label: "Error Backpack Open - " + attempt_response.error })
                setAttemptStarted(false);
            }
            
        } else {
            setUserCoins({
                ...userCoins,
                showMissingCoinsPopover: true
            })
        }
    }

    const handleStartBackgroundAnimation = (e) => {
        if (e.currentTime > 55){
            if(attemptResult)
                lottieBgRef.current.play();
            setShowAttemptResult(true)
        }
    }

    const handleEndBackpackAnimation = (e) => {
        console.log("Animation Complete")
        setAnimationPlaying(false)
        setAttemptStarted(false)
        if(attemptResult)
            setTimeout(() => {setShowPrizeModal(true)},3000)
        if (isFirstAttempt)
            setIsFirstAttempt(false)
    }

    const handleClosePrizeModal = async () => {
        setShowPrizeModal(false);
        setShowAttemptResult(false)
        setAttemptResult(null);
        loadUserCoins()
    }

    const loadUserCoins = async () => {
        if(firebase_idToken){
            let response = await CallServer.get_user_coins(firebase_idToken);
            if(response.success){
                setUserCoins({
                    ...userCoins,
                    total_coins: response.data.total_coins
                })
            }
        }
    }
    const loadContestInfo = async () => {
        let response = await CallServer.get_contest_info(firebase_idToken);
        if (response.success) {
            setContestInfo(response.data)
        }
    }

    const loadHasPendingPrize = async () => {
        if (firebase_idToken !== "") {
            let response = await CallServer.get_pending_rewards(firebase_idToken);
            if (response.success) {
                if (response.data.length > 0) {
                    setAttemptResult(response.data[0]);
                    setShowPrizeModal(true);
                }

            }
        }
    }

    const handleCloseErrorModal = (redirect) => {
        if(redirect)
            window.location = "https://thefacultyapp.com/?APP#BESTOF#GENERAL";
        setErrorModalDetails({
            ...errorModalDetails,
            show: false
        });
    }

    const loadRewardWindow = async () => {
        if(firebase_idToken && firebase_idToken != ""){
            let response = await CallServer.get_reward_window(firebase_idToken);
            if(response.success){
                setIsLoaded(true)
                if(new Date(response.data.start_date) >= new Date())
                    setErrorModalDetails({
                        ...errorModalDetails,
                        show: true,
                        title: "Alla prossima...",
                        text: "il prossimo apri e vinci inizierà a breve, torna su questa pagina tra poco per aprire uno zaino!",
                        cta_text: "Gioca una sfida",
                        redirect_in_app: true
                    })
                if((new Date()) >= new_contest && !GetFromLocalStorage("first_access"))
                    setShowContestModal(true)
                if((new Date()) >= new_contest && GetFromLocalStorage("first_access") && !GetFromLocalStorage("second_access"))
                    setShowInfoAvatar(true)   
            } else {
                if(!response.error)
                    setErrorModalDetails({
                        ...errorModalDetails,
                        show: true,
                        title: "Alla prossima...",
                        text: "Per il momento non è previsto nessun'altro Apri&Vinci, continua a giocare con le Sfide e torna a trovarci per scoprire i prossimi appuntamenti!",
                        cta_text: "Gioca una sfida",
                        redirect_in_app: true
                    })
                else
                    setErrorModalDetails({
                        show:true,
                        title:"Oops!",
                        text: "C'è stato un errore durante il caricamento del concorso!, prova a chiudere e riaprire l'app!"
                    })
            }
        }
    }

    useEffect(()=>{
        if (!firebase_idToken || firebase_idToken === "") {
            navigate("/", { replace: true });
            return;
        }
        loadRewardWindow()
        loadUserCoins();
        loadContestInfo();
        loadHasPendingPrize();
    },[firebase_idToken])

    return (
        <div id="BackpackScreenContainer" style={{ height: '100vh', overflow:"hidden" }} >
            {!isloaded ? <div className="loader"></div> : ''}
                <div className="d-flex justify-content-between">
                    <CoinsCounter total_coins={userCoins.total_coins} />
                    <img src={question_bullet_icon} onClick={handleOpenInfoPopup} className="info_icon" />
                </div>
                <div id="animation_box">
                    <BackpackPopover show={showAttemptResult} reward={attemptResult ? attemptResult : null} is_winning_attempt={attemptResult ? true : false}/>
                    <Lottie
                        animationData={backpackBgAnimationData}
                        lottieRef={lottieBgRef}
                        autoplay={false}
                        loop={15}
                        initialSegment={[0, 60]}
                        id="backpack_bg_animation"
                        style={{opacity:(showAttemptResult && attemptResult? 1 : 0)}}
                    />
                    <Lottie
                        animationData={backpackAnimationData}
                        lottieRef={lottieRef}
                        autoplay={false}
                        loop={0}
                        onComplete={handleEndBackpackAnimation}
                        onEnterFrame={handleStartBackgroundAnimation}
                        initialSegment={[0,100]}
                        id="backpack_animation"
                        onClick={!attemptStarted ? handleAttemptLottery : null}
                    />
                </div>
                <div className="w-100" style={{position:"relative", zIndex:"10000"}}>
                    <MissingCoinsPopover show={userCoins.showMissingCoinsPopover} missing_coins_count={parseInt(contestInfo.lottery_attempt_currency_amount - userCoins.total_coins)} /> 
                <button onClick={handleAttemptLottery} className="attempt_button" disabled={attemptStarted}>{attemptStarted ? <><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></> : <>{isFirstAttempt ? <><img src={coin_icon} style={{ verticalAlign: "middle" }} /><span style={{fontSize:"24px"}}>{contestInfo.lottery_attempt_currency_amount}</span></> : <span>Apri un nuovo zaino</span>}</>}</button>
                </div>
                <div className="avatars_footer">
                <AvatarsPopover text={avatarsInfo.randomSentence} randomPosition={avatarsInfo.randomPosition} show={showAttemptResult} />
                    {
                        showAttemptResult ?
                            <>
                                {attemptResult ?
                                    <>
                                        <img src={user_left_happy} />
                                        <img src={user_right_happy} /> 
                                    </>
                                :
                                    <>
                                        <img src={user_left_sad} />
                                        <img src={user_right_sad} /> 
                                    </>
                                }
                            </>
                        :
                            <>
                                <img src={user_left} />
                                <img src={user_right} />
                            </>
                    }
                    
                </div>
                <InfoAvatar show={showInfoAvatar} handleClose={handleCloseInfoAvatar} />
                <ContestInfoModal show={showContestModal} handleClose={handleCloseContestModal} openMoreModal={handleOpenMoreInfo} />
                <InfoModal show={showInfoModal} handleClose={handleCloseInfoPopup} />
                <RedeemPrizeModal show={showPrizeModal} prize_data={attemptResult} handleClose={handleClosePrizeModal} />
                <ErrorModal show={errorModalDetails.show} handleClose={handleCloseErrorModal} title={errorModalDetails.title} text={errorModalDetails.text} cta_text={errorModalDetails.cta_text} redirect_in_app={errorModalDetails.redirect_in_app} />
        </div>
    );
}

export default BackpackScreen;

