import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { AppAuth } from "./Auth";
import { isJSON, generateID } from "./utils/index.js";
import * as gtag from './utils/gtag'

import InitialScreen from "./screens/InitialScreen/InitialScreen";
import BackpackScreen from "./screens/BackpackScreen/BackpackScreen";


function App() {

  const [loaded, setLoaded] = useState(false);
  const [isLoggedUser, setIsLoggedUser] = useState(false);
  const [firebase_idToken, setFirebase_idToken] = useState("");

  const value = { firebase_idToken, setFirebase_idToken };

  const SaveToLocalStorage = function (key, obj) {
    return localStorage.setItem(key, JSON.stringify(obj))
  }
  const getFromLocalStorage = function (key) {
    return JSON.parse(localStorage.getItem(key));
  }

  const location = useLocation()

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url)
    }
    handleRouteChange(location.pathname);
  }, [location])

  const new_contest = new Date("2022-08-01");

    const isToday = (someDate) => {
        const today = new Date()
        return someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
    }

  useEffect(() => {

    if (window.ReactNativeWebView !== undefined && (firebase_idToken === "" || (getFromLocalStorage(firebase_idToken) === null || getFromLocalStorage(firebase_idToken) !== firebase_idToken))) {
      var obj = { "needsUpdatedFirebaseIdToken": true };
      window.ReactNativeWebView.postMessage(JSON.stringify(obj));
    }

    //catch firebase token
    document.addEventListener("message", function (event) {
      var result = null;
      if (isJSON(event.data))
        result = event.data;
      else
        result = JSON.parse(event.data);

      if (!loaded) {
        if (result.firebase_idToken != null) {
          setFirebase_idToken(result.firebase_idToken);
          SaveToLocalStorage("firebase_idToken", result.firebase_idToken)
          setIsLoggedUser(true);
        }
      }
    });
    window.addEventListener("message", function (event) {
      var result = null;
      if (isJSON(event.data))
        result = event.data;
      else
        result = JSON.parse(event.data);
      if (!loaded) {
        if (result.firebase_idToken != null) {
          setFirebase_idToken(result.firebase_idToken);
          SaveToLocalStorage("firebase_idToken", result.firebase_idToken)
          setIsLoggedUser(true);
        }
      }
    });
  })
  return (
      <AppAuth.Provider value={value}>
        <div id="MainApp">
          <Routes>
            <Route index path="/" element={new Date() >= new_contest ? <BackpackScreen /> : <InitialScreen />} />
            <Route path="/backpackLottery" element={<BackpackScreen />} />
          </Routes>
        </div>
      </AppAuth.Provider>
  );
}

export default App;