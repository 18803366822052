import React, { useEffect, useState, useContext } from "react";
import Modal from "react-modal"
import gems_icon from "../../assets/images/icons/gems.svg";
import coins_icon from '../../assets/images/icons/coins.svg'

import CallServer from "../../apis/apis"
import { AppAuth } from "../../Auth";
import * as gtag from '../../utils/gtag' 

import "../_components/Modal.css";
import ErrorModal from "../_components/ErrorModal";
import RejectPrizemodal from "./RejectPrizeModal";
import SuccessRedeemModal from "./SuccessRedeemModal";

export default function RedeemPrizeModal(props) {
    const { firebase_idToken, setFirebase_idToken } = useContext(AppAuth);
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userSurname, setUserSurname] = useState("");

    const [showSuccessRedeemModal, setShowSuccessRedeemModal] = useState(false);
    const [showRejectPrizeModal, setShowRejectPrizeModal] = useState(false);
    const [winnedReward, setWinnedReward] = useState({});

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorText, setErrorText] = useState("");

    const style = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 10000
        },
        content: {
            position: 'absolute',
            left: '0',
            bottom: '0',
            width: '100%',
            border: '0',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderTopRightRadius: '20px',
            borderTopLeftRadius: '20px',
            outline: 'none',
            padding: '0'
        }
    }

    useEffect (() => {
        const get_user_data = async () =>{
            if(firebase_idToken !== ""){
                let response = await CallServer.get_user_data(firebase_idToken);
                if(response.success){
                    setUserName(response.data.firstname);
                    setUserSurname(response.data.lastname);
                    setUserEmail(response.data.email);
                }
            }
        }
        get_user_data();
    }, [firebase_idToken])

    const prize_info = props.prize_data;

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handleConfirmPrizeReject = () => {
        setShowRejectPrizeModal(true);
    }
    const handleCloseRejectPrizeModal = () => {
        setShowRejectPrizeModal(false);
    }

    const handleConfirmPrizeReddem = async () => {
        if(userName=== "" || userEmail === "" || userSurname === ""  || !validateEmail(userEmail)){
            setErrorTitle("Controlla i dati!");
            setErrorText("Alcuni dei dati inseriti non sono corretti, controlla di aver inserito correttamente il tuo nome e cognome, codice fiscale ed E-mail!");
            setShowErrorModal(true);
            return
        }
        let response = await CallServer.complete_reward(firebase_idToken,prize_info.reward_id,userName,userSurname,userEmail);
        if(response.success){
            props.handleClose();
            setWinnedReward(prize_info)
            setShowSuccessRedeemModal(true);
            gtag.event({ action: "redeem_reward_success", category: "User Actions", label: "Reward Redeem Success" })
        } else {
            setErrorText("");
            setErrorTitle("");
            setShowErrorModal(true);
            console.log("Spin Error ->" + response.error);
        }
    }

    const handleRejectReward = async () => {
        let response = await CallServer.reject_reward(firebase_idToken, prize_info.reward_id);
        if (response.success) {
            props.handleClose();
            setShowRejectPrizeModal(false)
            gtag.event({ action: "reject_reward", category: "User Actions", label: "Reward Reject" })
        } else {
            setErrorText("");
            setErrorTitle("");
            setShowErrorModal(true);
            console.log("Spin Error ->" + response.error);
        }
    }

    const handleCloseErrorModal = () => {
        setShowErrorModal(false);
    }
    const handleCloseSuccessModal = () => {
        setShowSuccessRedeemModal(false);
    }

    const handleUserEmail = (event) => {
        setUserEmail(event.target.value);
    }
    const handleUserName = (event) => {
        setUserName(event.target.value);
    }
    const handleUserSurname = (event) => {
        setUserSurname(event.target.value);
    }

    const renderImage = () => {
        if(props.prize_data){
        switch(props.prize_data.action_function){
            case 'gems_action':
                return (
                    <img
                        alt="Icona Premio"
                        className="noselect header_image"
                        src={gems_icon}
                    />
                )
            break;
            case 'coins_action':
                return (
                    <img
                        alt="Icona Premio"
                        className="noselect header_image"
                        src={coins_icon}
                    />
                )
            break;
            case 'generic_action':
                return (
                    <img
                        alt="Icona Premio"
                        className="noselect header_image"
                        src={props.prize_data.image_url}
                    />
                )
            break;
        }
    }
}

    return (
        <>
            {prize_info ? 
            <Modal
                closeTimeoutMS={300}
                isOpen={props.show}
                contentLabel="modal"
                className="bottom_modal"
                overlayClassName="bottom_modal_overlay"
                ariaHideApp={false}
                style={style}
            >
                <div className="modal-content">
                    <div className="d-flex justify-content-center">
                        {renderImage()}    
                    </div>
                    <h2 id="modalTitle" style={{marginBottom:"24px", marginTop:"16px"}}>Wow, Hai vinto {prize_info?.description}!</h2>
                    <p style={{textAlign:"center", marginTop:"16px", marginBottom:"32px"}}>
                            {prize_info.action_function === "generic_action" ? <span>Conferma subito i tuoi dati o modificali per riscattare il premio, <strong>riceverai una e-mail con tutti i dettagli sulla consegna del premio.</strong></span> : "Conferma subito i tuoi dati o modificali per riscattare il premio e accreditarlo sul tuo account."}
                    </p>
                    <div id="userDataForm">
                        <div className="input-group">
                            <label>Nome </label>
                            <input type="text" id="userName" placeholder="Mario" value={userName} onChange={handleUserName} />
                        </div>
                        <div className="input-group">
                            <label>Cognome</label>
                            <input type="text" id="userSurname" placeholder="Rossi" value={userSurname} onChange={handleUserSurname} />
                        </div>
                        <div className="input-group">
                            <label>E-mail</label>
                            <input type="email" id="userEmail" placeholder="mariorossi@gmail.com" value={userEmail} onChange={handleUserEmail}/>
                        </div>
                    </div>
                    <p style={{textAlign:"center", marginTop:"16px", marginBottom:"16px", fontSize:"12px"}}>
                        Attenzione! se non lo confermi entro 20 min, il premio verrà riassegnato automaticamente.
                    </p>
                    <button id="closeModalButton" onClick={handleConfirmPrizeReddem}>
                        Conferma e riscatta
                    </button>
                    <button id="fullRulesButton" className="grey_btn" onClick={handleConfirmPrizeReject} style={{marginBottom:"16px"}} >
                        Rifiuta premio 
                    </button>
                </div>
            </Modal>
            : "" }
            <ErrorModal show={showErrorModal} handleClose={handleCloseErrorModal} title={errorTitle} text={errorText}/>
            <RejectPrizemodal show={showRejectPrizeModal} handleClose={handleCloseRejectPrizeModal} handleRejectReward={handleRejectReward}/>
            <SuccessRedeemModal show={showSuccessRedeemModal} handleClose={handleCloseSuccessModal} reward={winnedReward} />
        </>
    );
}