import { useEffect } from "react";

import gems_icon from '../../assets/images/icons/gems.svg'
import avatar_single from "../../assets/images/icons/avatar_single.svg"

import "./InfoAvatar.css"

export default function InfoAvatar(props) {
    useEffect(() => {

    }, [props])

    const avatarsPopoverPosition = [
        "position_left", "position_right"
    ]

    return (
        <>
            {props.show ?
                <div className="avatar_single" onClick={props.handleClose}> 
                    <div className="single_avatar_container">      
                        <div id="avatar_popover_container" className="show">
                            <div>
                                <span className="popover_text">Hai ancora domande? Qui sopra troverai tutte le <strong className="text-tf fw-800">FAQ</strong> aggiornate e il <strong className="text-tf fw-800">regolamento</strong> del concorso!</span>
                            </div>
                        </div>
                        <img src={avatar_single} className="avatar_bottom"/>
                    </div> 
                </div>
            : <></>}
        </>
    )
}