export const avatars_sentences = [
    "Deludente come il primo esame della triennale...",
    "Vuoto come la mia dispensa da fuorisede",
    "Triste come gli esami da dare a settembre",
    "#MaiUnaGioia",
    "Un altro zaino aperto, ancora nulla di certo",
    "Apri & Vinci una bega di niente",
    "La solita sfiga... spero vada meglio in amore",
    "Vuoto come il mio cuore ogni mattina quando devo prendere il treno",
    "Okayyyy let’s go",
    "Il vuoto cosmico",
    "Ritenta, sarai più fortunato (dicono...)",
    "Povero Gabbiano, è vuoto pure questo zainooooo",
    "Vuoto come la mia mente durante l’esame",
    "E anche oggi si diventa ricchi domani!",
    "Triste come 17 con riserva e poi ti boccia"
]