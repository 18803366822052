import { useEffect } from "react";

import gems_icon from '../../assets/images/icons/gems.svg'
import coins_icon from '../../assets/images/icons/coins.svg'
import web_icon from '../../assets/images/icons/web.svg'

import "./BackpackPopover.css"

export default function BackpackPopover(props) {
    useEffect(() => {

    }, [props])

    return (
        <div id="popover_container" className={props.show ? "show" : ""}>
            <div>
            {props.is_winning_attempt ?
                <>
                    {
                        props.reward.action_function == "generic_action" ?
                            <img src={props.reward.image_url} className="prize_icon"/>
                        : <>
                            {props.reward.action_function == "gems_action" ?
                                <img src={gems_icon} className="gems_icon"/> :
                                 <img src={coins_icon} className="gems_icon"/>
                            }
                        </>
                    }
                </>
            : <img src={web_icon} className="web_icon" /> }
            {props.is_winning_attempt ?
                <>
                    { props.reward?.action_function == "gems_action" || props.reward?.action_function == "coins_action" ?
                        <span className="gems_count">{props.reward?.gems ? props.reward?.gems : props.reward?.coins}</span> :     
                    <span className="popover_text">{props.reward?.title}</span> }
                </>
            : <span className="popover_text">Questa volta non hai vinto... </span> }
            </div>
            
        </div>
    )
}