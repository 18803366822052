const API_URL = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'http://127.0.0.1:8000/api' : window.location.origin + '/api';
const EXTERNAL_API_URL = process.env.REACT_APP_MOBILE_API_URL;

class server {
    async standard_api_call(api_url) {
        try {
            let _t = await fetch(api_url, {
                method: 'GET',
                cache: 'no-cache'
            });
            return _t.json();
        } catch (e) {
            return { success: false, error: e.message };
        }
    }
    async post_api_call(api_url, body = null) {
        try {
            let options = {
                method: 'POST',
                cache: 'no-cache'
            }
            if(body){
                var formData = new FormData();

                formData.append("firstname", body.firstname);
                formData.append("lastname", body.lastname); 
                formData.append("email", body.email); 

                options.body = formData;
            } 
            let _t = await fetch(api_url, options);
            return _t.json();
        } catch (e) {
            return { success: false, error: e.message };
        }
    }
    async external_api_call(api_url,body_data) {
        try {
            let _t = await fetch(api_url, {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    "Content-Type":"application/json"
                },
                body: JSON.stringify(body_data)
            });
            return _t.json();
        } catch (e) {
            return { success: false, error: e.message };
        }
    }

    async get_contest_info() {
        let api_url = API_URL + `/get_contest_info`;
        const response = await this.standard_api_call(api_url);
        if (response == null) return { success: false, data: null };
        if (response.success === false || response.detail) return { success: false, error: response.detail ? response.detail : response.error };
        return { success: true, data: response };
    }

    async get_user_coins(firebase_idToken) {
        let api_url = API_URL + `/get_coins?firebase_idToken=${firebase_idToken}`;
        const response = await this.standard_api_call(api_url);
        if (response == null) return { success: false, data: null };
        if (response.success === false || response.detail) return { success: false, error: response.detail ? response.detail : response.error };
        return { success: true, data: response };
    }

    async redeem_referral(firebase_idToken, referral_code) {
        let api_url = API_URL + `/use_referral_code?firebase_idToken=${firebase_idToken}&referral_code=${referral_code}`;
        const response = await this.post_api_call(api_url);
        if (response == null) return { success: false, data: null };
        if (response.success === false || response.detail) return { success: false, error: response.detail ? response.detail : response.error };
        return { success: true, data: response };
    }

    async complete_reward(firebase_idToken, reward_id, firstname, lastname, email) {
        let api_url = API_URL + `/complete_reward?firebase_idToken=${firebase_idToken}&reward_id=${reward_id}`;
        const response = await this.post_api_call(api_url,{firstname:firstname, lastname:lastname, email: email});
        if (response == null) return { success: false, data: null };
        if (response.success === false || response.detail) return { success: false, error: response.detail ? response.detail : response.error };
        return { success: true, data: response };
    }

    async reject_reward(firebase_idToken,reward_id) {
        let api_url = API_URL + `/reject_reward?firebase_idToken=${firebase_idToken}&reward_id=${reward_id}`;
        const response = await this.standard_api_call(api_url);
        if (response == null) return { success: false, data: null, error:null };
        if (response.success === false || response.detail) return { success: false, error: response.detail ? response.detail : response.error };
        return { success: true, data: response };
    }

    async attempt_lottery(firebase_idToken) {
        let api_url = API_URL + `/attempt_lottery?firebase_idToken=${firebase_idToken}`;
        const response = await this.standard_api_call(api_url);
        if (response == null) return { success: true, data: null, error: null };
        if (response.success === false || response.detail) return { success: false, error: response.detail ? response.detail : response.error };
        return { success: true, data: response };
    }

    async get_reward_window(firebase_idToken) {
        let api_url = API_URL + `/get_reward_window?firebase_idToken=${firebase_idToken}`;
        const response = await this.standard_api_call(api_url);
        if (response == null) return { success: false, data: null, error: null };
        if (response.success === false || response.detail) return { success: false, error: response.detail ? response.detail : response.error };
        return { success: true, data: response };
    }

    async get_available_rewards() {
        let api_url = API_URL + `/get_rewards`;
        const response = await this.standard_api_call(api_url);
        if (response == null) return { success: false, data: null, error: null };
        if (response.success === false || response.detail) return { success: false, error: response.detail ? response.detail : response.error };
        return { success: true, data: response };
    }

    async get_pending_rewards(firebase_idToken) {
        let api_url = API_URL + `/get_pending_rewards?firebase_idToken=${firebase_idToken}`;
        const response = await this.standard_api_call(api_url);
        if (response == null) return { success: false, data: null, error: null };
        if (response.success === false || response.detail) return { success: false, error: response.detail ? response.detail : response.error };
        return { success: true, data: response };
    }

    async get_user_data(firebase_idToken) {
        let api_url = EXTERNAL_API_URL + '/profile/get_user_data';
        const response = await this.external_api_call(api_url,{firebase_idToken: firebase_idToken});
        if (response == null) return { success: false, data: null, error: null };
        if (response.success === false || response.detail) return { success: false, error: response.detail ? response.detail : response.error };
        return { success: true, data: response.data };
    }
}

export default new server();
