import { useEffect } from "react";

import gems_icon from '../../assets/images/icons/gems.svg'

import "./BackpackPopover.css"

export default function AvatarsPopover(props) {
    useEffect(() => {

    }, [props])

    const avatarsPopoverPosition = [
        "position_left", "position_right"
    ]

    return (
        <div id="avatars_popover_container" className={(props.show ? "show" : "") + " " + avatarsPopoverPosition[[Math.floor(props.randomPosition * avatarsPopoverPosition.length)]]}>
            <div>
                <span className="popover_text">{props.text}</span>
            </div>

        </div>
    )
}