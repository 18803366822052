import Modal from "react-modal"
import { useEffect, useLayoutEffect, useRef } from "react";
import * as gtag from '../../utils/gtag' 

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import "./Modal.css"

import coin_icon from "../../assets/images/icons/coin.svg";
import timer_icon from "../../assets/images/icons/timer.svg";
import arrow_icon from "../../assets/images/icons/arrow_accordion.svg";
import backpack_img from "../../assets/images/icons/backpack.svg";
import gem_icon from "../../assets/images/icons/gem.svg";
import question_icon from "../../assets/images/icons/question.svg";
import shop_icon from "../../assets/images/icons/shop.svg";
import card_icon from "../../assets/images/icons/gem-card.svg";
import prize_icon from "../../assets/images/icons/prize.svg";


export default function InfoModal(props) {
    const onCloseModal = () => {
        props.handleClose();
    }
    const style = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 10000
        },
        content: {
            position: 'absolute',
            left: '0',
            bottom: '0',
            width: '100%',
            border: '0',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderTopRightRadius: '20px',
            borderTopLeftRadius: '20px',
            outline: 'none',
            padding: '0'
        }
    }
    const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <span>0 giorni</span>;
        } else {
            // Render a countdown
            return <span>{days}gg {hours}h {minutes}min</span>;
        }
    };

    const now = new Date();
    const firstPrize = new Date("2022-01-19");
    const secondPrize = new Date("2022-01-29");

    const accordionRef = useRef(new Array())

    const scrollToTop = (e) => {
        let fields = Object.keys(accordionRef).length !== 0 ? accordionRef.current : {};
        if (Object.keys(fields).length === 0){
            return;
        }    
        for (let i in fields) {
            let div = fields[i]
            if(div)
                if (div.parentElement.id === 'accordion__panel-'+e[0])
                    div.parentElement.scrollIntoView()
        }
        gtag.event({ action: "open_accordion_info", category: "User Info Action", label: "Open Info Accordion - "+e[0] })
    };

    useLayoutEffect(() => {
    })

    useEffect(() => {

    },[props])

    return (
        <>
            <Modal
                closeTimeoutMS={300}
                isOpen={props.show}
                contentLabel="modal"
                onRequestClose={onCloseModal}
                className="bottom_modal"
                overlayClassName="bottom_modal_overlay"
                ariaHideApp={false}
                style={style}
            >
                <div className="modal-content">
                    <div className="d-flex justify-content-center">
                        <img
                            alt="Faq"
                            className="noselect header_image"
                            src={question_icon}
                        />
                    </div>
                    <h2 id="modalTitle">Domande frequenti</h2>
                    <div id="accordionContainer">
                    <Accordion 
                        allowZeroExpanded={true}
                        allowMultipleExpanded={false}
                        onChange={scrollToTop}
                        >
                        <AccordionItem >
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                        <div>
                                            <img src={backpack_img} className="inline_icon" style={{ marginRight: "8px" }} />Cosa sono gli zaini?
                                        </div>
                                        <img src={arrow_icon} alt=">" className="control_arrow"/>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                    <p ref={(element) => accordionRef.current[0] = (element)}>
                                        Con <img src={coin_icon} className="inline_icon" />100 puoi aprire uno zaino. Ci sono decine di premi in palio assegnati casualmente, da 5 fino a 50 <img src={gem_icon} className="inline_icon" />gemme... per un totale di oltre 2000€. Tenta la fortuna! 
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <div>
                                            <img src={gem_icon} className="inline_icon" style={{ marginRight: "8px" }} />Cosa sono le gemme?
                                        </div>
                                        <img src={arrow_icon} alt=">" className="control_arrow" />
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p ref={(element) => accordionRef.current[1] = (element)}>
                                        1<img src={gem_icon} className="inline_icon" /> = 1€. Puoi usare le tue gemme nel <img src={shop_icon} className="inline_icon" /> Negozio per sbloccare tantissime <img src={card_icon} className="inline_icon" /> gem card (ovvero gift card) dei nostri partner. Inoltre, al contrario dei gettoni, le gemme non scadono mai!
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <div>
                                            <img src={prize_icon} className="inline_icon" style={{ marginRight: "8px" }} />Quali altri premi ci sono?
                                        </div>
                                        <img src={arrow_icon} alt=">" className="control_arrow" />
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p ref={(element) => accordionRef.current[2] = (element)}>
                                        Apri & Vinci quest’anno è ancora più ricco: a maggio potrai trovare negli zaini gustosi premi Wagamama, a giugno invece biglietti Gardaland!
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <div>
                                            <img src={timer_icon} className="inline_icon" style={{ marginRight: "8px" }} />Cos’è il reset mensile dei gettoni?
                                        </div>
                                        <img src={arrow_icon} alt=">" className="control_arrow" />
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p ref={(element) => accordionRef.current[3] = (element)}>
                                        I <img src={coin_icon} className="inline_icon" /> gettoni ti permettono di sbloccare sconti esclusivi nel <img src={shop_icon} className="inline_icon" />Negozio. Tuttavia, il primo giorno di ogni mese, i gettoni si azzerano. Per questo abbiamo deciso di dare loro ancora più valore, regalandoti l’opportunità di trasformarli in gemme prima che scadano!
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                        <div>
                                            <img src={coin_icon} className="inline_icon" style={{ marginRight: "8px" }} />Come guadagno gettoni?
                                        </div>
                                        <img src={arrow_icon} alt=">" className="control_arrow" />
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                    <p ref={(element) => accordionRef.current[4] = (element)}>
                                        Ogni <a href="https://thefacultyapp.com/?APP#BESTOF#GENERAL">sfida</a> che completi ti fa guadagnare dei gettoni, a seconda che tu vinca (<img src={coin_icon} className="inline_icon" />15), pareggi (<img src={coin_icon} className="inline_icon" />10) o perda (<img src={coin_icon} className="inline_icon" />5). Seguici anche su <a href="https://www.instagram.com/thefacultyapp/?hl=it">Instagram</a> e rispondi ai sondaggi nelle storie per guadagnare centinaia di gettoni!
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                    </div>
                    <a href="mailto:assistenza@thefaculty.it" title="Scrivi alla nostra assistenza" className="modalLink">Domande? Contatta la nostra assistenza</a>
                    <button id="closeModalButton" onClick={onCloseModal} >
                        Ho capito!
                    </button>
                    <a href="https://storage.it.thefacultyapp.com/legals/reg_aev23.pdf" title="Regolamento Apri & Vinci"><button className="grey_btn" id="fullRulesButton" >
                        Regolamento Completo
                    </button> </a>
                </div>
            </Modal>
        </>
    );
}

