import React, { useEffect } from "react";
import Modal from "react-modal"
import ok_img from "../../assets/images/icons/correct.svg";
import shop_img from "../../assets/images/icons/shop.svg";
import gem_icon from "../../assets/images/icons/gem.svg";
import coin_icon from "../../assets/images/icons/coin.svg";
import "./SuccessRedeemModal.css";
import * as gtag from '../../utils/gtag' 

export default function SuccessRedeemModal(props) {
    const onCloseModal = () => {
        props.handleClose();
    }
    const style = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 10020
        },
        content: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: "translate(-50%, -50%)",
            width: '90%',
            border: '0',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: "16px",
            outline: 'none',
            padding: '0'
        }
    }

    useEffect(() => {

    }, [props])

    return (
        <>
            <Modal
                closeTimeoutMS={300}
                isOpen={props.show}
                contentLabel="modal"
                onRequestClose={onCloseModal}
                className="center_modal"
                overlayClassName="bottom_modal_overlay"
                ariaHideApp={false}
                style={style}
            >
                <div className="modal-content">
                    <div className="d-flex justify-content-center">
                        <img
                            alt="Spin"
                            className="noselect header_image"
                            src={ok_img}
                        />
                    </div>
                    {props.reward?.action_function === "generic_action" ?
                        <h2 id="modalTitle" style={{ textAlign: 'center', marginBottom: "16px", marginTop: "16px" }}>Premio riscattato!</h2>
                    : <h2 id="modalTitle" style={{ textAlign: 'center', marginBottom: "16px", marginTop: "16px" }}>{props.reward?.action_function === "gems_action" ? "Gemme riscattate" : "Gettoni riscattati"}!</h2> }
                    {props.reward?.action_function !== "generic_action" ?
                        <p style={{ textAlign: 'center', marginBottom: "32px" }}>
                            <span>Complimenti, abbiamo appena accreditato {props.reward?.action_function === "gems_action" ? <img src={gem_icon} alt="" className="inline_icon" /> : <img src={coin_icon} alt="" className="inline_icon" /> } { props.reward?.action_function === "gems_action" ? props.reward?.gems : props.reward?.coins} sul tuo account! {props.reward?.action_function === "gems_action" ? <>Usale nel <img src={shop_img} alt="" className="inline_icon" /> Negozio per sbloccare tantissime gift card! Torna il mese prossimo per aprire altri zaini!</> : "" }</span>
                        </p>
                    : <p style={{ textAlign: 'center', marginBottom: "32px" }}>
                            <span>Complimenti, abbiamo registrato la tua vincita! <strong>Ti abbiamo inviato un mail di conferma!</strong></span>
                        </p>
                    }
                    {props.reward?.gems ? 
                        <a href="https://thefacultyapp.com/?APP#COUPONS#SPECIAL">
                            <button id="closeModalButton" onClick={() => { gtag.event({ action: "open_app_gems_section", category: "User Actions", label:"Open App Gems Section" })}}>
                                Vai al negozio!
                    </button></a>
                    :""}
                    <button id="fullRulesButton" className="grey_btn" onClick={onCloseModal} >
                        Chiudi
                    </button>

                </div>
            </Modal>
        </>
    );
}